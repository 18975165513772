.swal2-overlay {
  background-color: rgba(0, 0, 0, 1 );
}

.swal2-modal {
  /* background-color: rgba(63,255,106,0.69); */
  border: 3px solid white;
  /* font-size: 16px; */
}
.swal2-title {
  margin: 0px;
  font-size: 22px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-bottom: 28px;
}
.swal2-text {
  font-size: 16px;
}
.swal2-html-container {
  font-size: 16px;
}
