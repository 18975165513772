
.fade_kitty {
    transition: opacity 0.2s linear;
}
/* 
@media (prefers-reduced-motion: reduce) {
    .fade_kitty {
        transition: none;
    }
} */

.fade_kitty:not(.show) {
    opacity: 0;
}

.modal.fade_kitty .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
/* 
@media (prefers-reduced-motion: reduce) {
    .modal.fade_kitty .modal-dialog {
        transition: none;
    }
} */
/*  
@page {
    size: a4;
} */

body {
    min-width: 1px !important;
}
.container {
    min-width: 1px !important;
}
